<template>
  <c-flex
    flex-direction="column"
    border="1px solid #F2F2F2"
    box-shadow="0px 5px 30px rgba(0, 0, 0, 0.05)"
    border-radius="md"
    mt="10px"
    px="20px"
    :w="['100%', '100%']"
  >
    <c-flex
      h="50px"
      border-bottom="1px solid #f4f4f4"
      align-items="center"
      justify-content="space-between"
      px="22px"
    >
      <c-flex>
        <c-text
          font-weight="bold"
          font-size="14px"
          line-height="150%"
          color="#111111"
        >
          {{ title }}
        </c-text>
        <c-text font-size="14px" line-height="150%" color="#111111" ml="5px">
          ({{ level }})
        </c-text>
      </c-flex>
      <!-- <c-image
          :src="require('@/assets/icon-chevron-right-filled.svg')"
          alt="icon arrow right"
          w="18px"
          h="18px"
      /> -->
    </c-flex>
    <table v-chakra mx="0" my="15px">
      <tr v-chakra h="25px">
        <td v-chakra w="100px" font-size="12px" color="brand.900">Ahli Gizi</td>
        <td
          v-chakra
          max-width="100px"
          font-weight="500"
          font-size="12px"
          color="#333"
          white-space="nowrap"
          text-overflow="ellipsis"
          overflow="hidden"
        >
          {{ getNutritionistName(ahliGizi) }}
        </td>
      </tr>
      <tr v-chakra h="25px">
        <td v-chakra font-size="12px" color="brand.900">Durasi</td>
        <td v-chakra font-weight="500" font-size="12px" color="#333">
          {{ duration }}
        </td>
      </tr>
      <tr v-chakra h="25px">
        <td v-chakra font-size="12px" color="brand.900">Status</td>
        <td v-chakra font-weight="500" font-size="12px" color="#333">
          {{ status }}
        </td>
      </tr>
      <tr v-chakra h="25px">
        <td v-chakra font-size="12px" color="brand.900">Tanggal Mulai</td>
        <td v-chakra font-weight="500" font-size="12px" color="#333">
          {{ dateStart | optional | formatDate("DD MMM YYYY") }}
        </td>
      </tr>
      <tr v-chakra h="25px">
        <td v-chakra font-size="12px" color="brand.900">Tanggal Selesai</td>
        <td v-chakra font-weight="500" font-size="12px" color="#333">
          {{ dateEnd | optional | formatDate("DD MMM YYYY") }}
        </td>
      </tr>
      <tr v-chakra h="25px">
        <td v-chakra font-size="12px" color="brand.900">Harga</td>
        <td v-chakra font-weight="500" font-size="12px" color="#333">
          {{ price | optional }}
        </td>
      </tr>
      <tr v-chakra h="25px">
        <td v-chakra font-size="12px" color="brand.900">Kupon yang diterapkan</td>
        <td v-chakra font-weight="500" font-size="12px" color="#333">
          {{ coupon | optional }}
        </td>
      </tr>
    </table>
  </c-flex>
</template>

<script>
export default {
  name: "program-card",
  props: [
    "title",
    "level",
    "ahliGizi",
    "duration",
    "status",
    "dateStart",
    "dateEnd",
    "price",
    "coupon",
  ],
  methods: {
    getNutritionistName(data) {
      if (data) {
        return (
          data.firstName +
          `${data.lastName ? " " + data.lastName : ""} ${data.title ?? ""}`
        );
      }
      return "-";
    },
  },
  filters: {
    optional(val) {
      if (val == null) return "-";
      return val;
    }
  },
};
</script>

<style scoped></style>
